%header-item{
    @extend %flex;
    @extend .row;
    @extend .center;
    @extend .no-wrap;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    @include media-breakpoint-up(md){
        padding-top: 1em;
        padding-bottom: 1em;
    }
    color: $color-prime;
    .fas{
        font-size: 1.5rem;
        // padding-right: 0.5rem;
    }
    
}
%header-link{
    border-bottom: 6px solid transparent;
    margin-bottom: -6px;
    @include media-breakpoint-up(xl){
        border-bottom: 8px solid transparent;
        margin-bottom: -8px;
    }
    @include media-breakpoint-up(xxl){
        border-bottom: 12px solid transparent;
        margin-bottom: -12px;
    }

    .fas{
        color: $color-prime;
    }
    &:hover, &.active{
        color: $color-second;
        text-decoration: none;
        border-color: $color-prime;
        cursor: pointer;
    }
}

.header{
    background-color: #fff;
    color: $color-prime;
    
    height: 68px;
    @include media-breakpoint-up(sm){ height: 80px; }
    @include media-breakpoint-up(xl){ height: 82px; }
    @include media-breakpoint-up(xxl){ height: 106px; }

    border-bottom: 6px solid $color-second;

    @include media-breakpoint-up(xl){
        border-bottom: 8px solid $color-second;
    }
    @include media-breakpoint-up(xxl){
        border-bottom: 12px solid $color-second;
    }
    &__item{@extend %header-item;}
    &__link{@extend %header-link;}

    &__logo{
        @extend %header-item;
        img{            
            width: auto;
            height: 42px;
            @include media-breakpoint-up(xxl){
                height: 62px;
            }
        }
        a{
            font-family: $ff-h-bold;
            color: $color-prime;
            text-decoration: none;
        }
    }
    &__contacts{
        // @include media-breakpoint-up(xl){
        //     padding-left: 3rem;
        // }
    }
    &__icon{
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        align-items: center;
        align-content: center;
        font-size: 1.5rem;
        @include media-breakpoint-up(lg){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
        @include media-breakpoint-up(xl){
            padding-left: 0.8rem;
            padding-right: 0.8rem;
            font-size: 1.5rem;
        }
        @include media-breakpoint-up(xxl){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
    &__search-icon{
        .fas{ font-size: 1.2rem; }
        @include media-breakpoint-up(xxl){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
    &__user{
        @include media-breakpoint-up(xxl){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

    }
    &__phone__wrapper{
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        @include media-breakpoint-up(md){
            padding-left: 2.5rem;
            padding-right: 1.5rem;
        }
        @include media-breakpoint-up(xl){
            padding-left: 0.8rem;
            padding-right: 0.8rem;
        }
        @include media-breakpoint-up(xxl){
            padding-left: 2.5rem;
            padding-right: 1.5rem;
        }
    }
    &__phone{
        color: $color-prime;
        font-family: $ff-h-medium;
        line-height: 1em;
        text-decoration: none;
        font-size: 1.4rem;
        @include media-breakpoint-up(xxl){
            font-size: 1.6rem;

        }
        @include hover-focus-active {
            color: $color-gray-3;
        }
    }
    &__callback{
        font-size: 0.875rem;
    }

    &__user,
    &__search-icon,
    &__search-icon-close,
    &__mob-menu-btn{
        @extend %header-item;
        @extend %header-link;
    }
    .whatsapp-link{
        color: #43D854;
    }
    .telegram-link{
        color: #229ED9;
    }
    .email-link{
        
    }

    &__search-form{
        padding-left: 90px;
    }
    &__search-form,
    &__search-icon-close {
        visibility: hidden;
        opacity: 0;
        display: none;
    }
    &__menu-trigger{
        &__title{
            padding-right: 1rem;

            font-family: $ff-h-bold;
            font-size: 1.125em;
        }
        a{
            @extend %header-item;
            @extend %header-link;
            text-decoration: none;

            
            // @include media-breakpoint-up(sm){ width: 70px;}
            // @include media-breakpoint-up(lg){ width: 80px;}

            .fa-times{ display: none }
            .fa-bars{ display: block }
            &:active, &.active{
                .fa-times{ display: block }
                .fa-bars{ display: none }
            }
        }
    }
}

.header.sticky{
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 800;
}
.header.sticky + main{
    padding-top: 68px;
    @include media-breakpoint-up(sm){ padding-top: 80px; }
    @include media-breakpoint-up(xl){ padding-top: 82px; }
    @include media-breakpoint-up(xxl){ padding-top: 106px; }
}
.main, .header{
    transform: none;
    transition-duration:unset;
}

.header-mobile{
    height: 60px;
    background-color: #fff;

    color: #fff;
    font-size: 14px;
    border-bottom: 8px solid $color-second;
}



%l-header__item{
    @extend .row;
    @extend .center;
    @extend .no-wrap;
    color: $color-clouds;
    .fas{
        font-size: 18px;
        padding-right: 0.5rem;
    }
}
%l-header__link{
    &:hover{
        background-color: #414141;
        color: $color-clouds;
        text-decoration: none;
        .l-header__callback__title{
            color: $color-clouds;
        }
    }
}

.l-header{
    height: 70px;
    background-color: $color-prime;
    
    &__logo, &__menu-trigger, &__phone, &__callback, &__email{
        @extend %l-header__item;
    }
    &__phone, &__callback, &__email{
        @extend %l-header__link;
        // padding-left: 2rem; padding-right: 2rem;
        padding: 0.5rem 2rem;
    }

    &__logo{
        img{
            height: 26px;
            padding-left: $grid-gutter/2;
            @include media-breakpoint-up(sm){ height: 30px;}
            @include media-breakpoint-up(lg){ height: 34px;}
            // @include media-breakpoint-up(xl){ height: 36px;}
        }
    }
    &__phone{
        span{
            font-family: $ff-h-normal;
            font-size: 22px;

            @include media-breakpoint-up(md){ font-size: 24px }
            // @include media-breakpoint-up(lg){ font-size: 26px }
        }
    }
    &__email{
        &__title{
            font-size: 16px;
        }
    }
    &__callback{
        flex-direction: column;
        @extend .j-center;

        text-decoration: none;

        &__title{
            font-size: 18px;
            color: $color-second;
            text-decoration: underline;
        }
        &__descr{
            font-size: 16px;
            font-family: $ff-italic;
        }

    }
    &__menu-trigger{
        // justify-self: flex-end;
        &__title{
            padding: 0 1rem;
            @include media-breakpoint-up(sm){
                padding: 0 1.5rem;
            }

            font-family: $ff-h-medium;
            font-size: 18px;
            color: $color-clouds;

        }
        a{
            @extend %flex;
            @extend .row;
            @extend .center;
            @extend .j-center;

            height: 100%;
            width: 48px;
            
            @include media-breakpoint-up(sm){ width: 70px;}
            // @include media-breakpoint-up(lg){ width: 80px;}

            font-size: 18px;
            color: $color-clouds;
            text-decoration: none;

            background-color: $color-second;

            .fas{ padding-left: 0.5rem }

            @include hover-focus-active{
                color: $color-clouds;
                background-color: #EB6F63;
            }
            .fa-times{ display: none }
            .fa-bars{ display: block }
            &:active, &.active{
                .fa-times{ display: block }
                .fa-bars{ display: none }
            }
        }
    }
}