%pseudo{
    position: absolute;
    left: 0rem;

    font-weight: 900;
    font-size: 2rem;
    line-height: 1.2rem;

    text-align: center;

    transition: all .3;
}

.footer{
    padding-top: 3rem;
    padding-bottom: 2.5rem;

    color: $color-clouds;
    background-color: $color-ad;
    a{
        color: $color-clouds;
    }
    &__item{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    &__company-name{
        padding-bottom: 1.5rem;
        font-family: $ff-h-bold;
        font-size: 2rem;
    }
    &__address{
        font-family: $ff-italic;
        padding-bottom: 1rem;
    }
    &__phone{
        padding-top: 0.5rem;

        font-family: $ff-h-medium;
        font-size: 1.5rem;
    }
    &__ruls{
        padding-bottom: 1.5rem;
        padding-left: 3rem;
        &:before{
            @extend %pseudo;
            font-family: $ff-fa;
            top: 4px;
            content: "\f573";
            line-height: 2rem;
        }
    }
    &__youtube{
        padding-left: 3rem;
        min-height: 2rem;
        &:before{
            @extend %pseudo;
            font-family: $ff-fa-b;
            content: "\f167";
        }
    }
    &__inst{
        padding-left: 3rem;
        min-height: 2rem;
        &:before{
            @extend %pseudo;
            font-family: $ff-fa-b;
            content: "\f16d";
        }
    }
}