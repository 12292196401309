.landing{
    &__banner{
        background-size: cover;
        height:380px;
        @include media-breakpoint-up(lg){
            height:540px;
        }
    }
}

.l-category-list{
    &__wrapper{
        @include media-breakpoint-only(xs){
            padding-left: 0;
            padding-right: 0;
        }
    }

    margin-top: 0rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md){
        margin-top: 1rem;
    }

    &__head{
        padding-top: 0;
        padding-bottom: 1.5rem;
        flex-direction: column;
        @include media-breakpoint-only(xs){
            padding-left: 0;
            padding-right: 0;
            img{
                margin-bottom: 1.5rem;
            }
        }
        @include media-breakpoint-up(sm){
                flex-direction: row;
                align-items: flex-end;
                align-content: flex-end;

                padding-bottom: 2rem;
        }
    }
    &__header{
        margin-bottom: 0.2em;
        @include media-breakpoint-only(xs){
            align-items: center;
            align-content: center;
        }
    }
    &__supheader{
        font-size: 1.25rem;
        color: #919191;
    }
    &__cards{
        @include media-breakpoint-only(xs){
            padding-left: 0;
            padding-right: 0;
        }
    }
    &.border{
        border-left: 2px solid #EEEEEE;
        @include media-breakpoint-only(xs){
            border-left: none;
        }
    }
}

