h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @extend %flex;
  margin-top: $h-margin-top;
  margin-bottom: $h-margin-bottom;
  font-family: $h-font-family;
  font-weight: $h-font-weight;
  color: $h-color;
  max-width: 100%;
  line-height: $h-line-height-xs;
  
  @include media-breakpoint-up(lg){ line-height: $h-line-height-lg; }
  @include media-breakpoint-up(xl){ line-height: $h-line-height-xl; }
}

h1, h2, .h1, .h2, h3, .h3{
  text-transform: none;
}


h1, .h1 { font-size: $h1-font-size * $xs-h-fz-factor; }
h2, .h2 { font-size: $h2-font-size * $xs-h-fz-factor; }
h3, .h3 { font-size: $h3-font-size * $xs-h-fz-factor; }
h4, .h4 { font-size: $h4-font-size * $xs-h-fz-factor; }
h5, .h5 { font-size: $h5-font-size * $xs-h-fz-factor; }
h6, .h6 { font-size: $h6-font-size * $xs-h-fz-factor; }

@include media-breakpoint-up(sm){
  h1, .h1 { font-size: $h1-font-size * $sm-h-fz-factor; }
  h2, .h2 { font-size: $h2-font-size * $sm-h-fz-factor; }
  h3, .h3 { font-size: $h3-font-size * $sm-h-fz-factor; }
  h4, .h4 { font-size: $h4-font-size * $sm-h-fz-factor; }
  h5, .h5 { font-size: $h5-font-size * $sm-h-fz-factor; }
  h6, .h6 { font-size: $h6-font-size * $sm-h-fz-factor; }
}
@include media-breakpoint-up(md){
  h1, .h1 { font-size: $h1-font-size * $md-h-fz-factor; }
  h2, .h2 { font-size: $h2-font-size * $md-h-fz-factor; }
  h3, .h3 { font-size: $h3-font-size * $md-h-fz-factor; }
  h4, .h4 { font-size: $h4-font-size * $md-h-fz-factor; }
  h5, .h5 { font-size: $h5-font-size * $md-h-fz-factor; }
  h6, .h6 { font-size: $h6-font-size * $md-h-fz-factor; }
}
@include media-breakpoint-up(lg){
  h1, .h1 { font-size: $h1-font-size * $lg-h-fz-factor; }
  h2, .h2 { font-size: $h2-font-size * $lg-h-fz-factor; }
  h3, .h3 { font-size: $h3-font-size * $lg-h-fz-factor; }
  h4, .h4 { font-size: $h4-font-size * $lg-h-fz-factor; }
  h5, .h5 { font-size: $h5-font-size * $lg-h-fz-factor; }
  h6, .h6 { font-size: $h6-font-size * $lg-h-fz-factor; }
}
@include media-breakpoint-up(xl){
  h1, .h1 { font-size: $h1-font-size * $xl-h-fz-factor; }
  h2, .h2 { font-size: $h2-font-size * $xl-h-fz-factor; }
  h3, .h3 { font-size: $h3-font-size * $xl-h-fz-factor; }
  h4, .h4 { font-size: $h4-font-size * $xl-h-fz-factor; }
  h5, .h5 { font-size: $h5-font-size * $xl-h-fz-factor; }
  h6, .h6 { font-size: $h6-font-size * $xl-h-fz-factor; }
}
@include media-breakpoint-up(xxl){
  h1, .h1 { font-size: $h1-font-size * $xxl-h-fz-factor; }
  h2, .h2 { font-size: $h2-font-size * $xxl-h-fz-factor; }
  h3, .h3 { font-size: $h3-font-size * $xxl-h-fz-factor; }
  h4, .h4 { font-size: $h4-font-size * $xxl-h-fz-factor; }
  h5, .h5 { font-size: $h5-font-size * $xxl-h-fz-factor; }
  h6, .h6 { font-size: $h6-font-size * $xxl-h-fz-factor; }
}

.items-name{
  font-family: $ff-h-bold;
  font-size: 1.22222rem;
  line-height: 1.3em;
}

.lead {
  font-size: $lead-font-size;
  font-family: $ff-italic;
  font-weight: 400;
  line-height: 1.4em;
}
.small{
  font-size: 0.8em;
  text-transform: none;
}

p{
  line-height: $paragraph-line-height;
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  width: 100%;
  font-size: 1rem;
}

ul{
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  padding-left: 2em;
  li{
    margin-bottom: .5em;
    line-height: $paragraph-line-height;
  }
  &.done-list{
    li{
      
    }
  }
}
dl{
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;

  dt{
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;

    font-family: $h-ff;
    color: $color-prime;
  }
  dd{
    margin-left: 0;
    padding-bottom: 1rem;
    ul{
      margin-bottom: 0;
    }
  }
}


.list-simple{
  &__group{
    padding-bottom: 1rem;
  }
  &__title{
    
  }
}

strong{
  font-family: $font-family-bold;
}
i{
  font-family: $font-family-italic;
}
.ff-medium{
  font-family: $ff-medium;
}

.ff-bold{
  font-family: $ff-bold;
}

.subheader{
  text-align: inherit;
  margin-top: -0.8em;
  margin-bottom: 1em;
  font-size: 1.125rem;
  font-family: $font-family-base;
}

.description{
  font-family: $ff-italic;
  padding-bottom: 1rem;
}
.table{
  .description{
    padding-bottom: 0;
  }
}

.initial-letter{
  padding-left: 1.5rem;  
}
.antiinitial-letter {
  padding-left: 0px !important;
}

.content_text{
  // flex-direction: column;
  line-height: $paragraph-line-height;
  font-size: 1rem;
  p{
    font-size: 1rem;
  }

  img{
    max-width: 100%;
    height: auto;
  }
}

.footnote{
  font-family: $ff-italic;
  line-height: $paragraph-line-height;

  padding: .6em 0 .6em 1.3em;
  margin-top: 1em;
  margin-bottom: 1em;
  border-left: 5px solid $color-gray-4;
}

.text_error{
  font-family: $ff-normal;
  color: $color-danger;
}

.color-second{
  color: $color-second!important;
}

.icon-prime-big{
  color: $color-second;
  font-size: 20px;
  margin-right: 0.5rem;
}

.fa-ruble-sign{
  font-size: 0.875rem;
  color: $color-gray-3;
}
.far{
  color: $color-second;
}