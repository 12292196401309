
$trunk-menu-size: 260px;

//Burger
.burger {
	position: absolute;
	float: left;
	padding: 10px;
	top: 4px;
	left: 10px;
	
	display: none;
}
.burger li {
	width: 30px;
	height: 4px;
	background-color: #fff;
	border-radius: 3px;
	margin: 5px 0;
}
.burger.open li {background-color: #d9dde1;}

// Nav
.trunk-nav, .trunk-category, .trunk-filter {
	position: absolute;
	top: 0;
	right: 5px;
}
// .trunk-nav li {
// 	float: left;
	
// 	display: inline-block;
// }
// .trunk-nav li a {
// 	font-size: 11px;
// 	color: #9aa6af;
// 	padding: 24px 15px;
	
// 	display: block;
// }
// .trunk-nav li a:hover {
//     color: #000;
// }

.trunk-content{
    min-height: 100vh;
}


@include media-breakpoint-down(xl) {

    .burger {display: block;}


    /*  Nav Drawer Layout  */
    .trunk-nav, .trunk-category, .trunk-filter {position: relative;}

    // .trunk-nav ul {
    //     height: 100%;
    //     overflow-y: auto;
    // }
    // .trunk-nav li {
    //     display: block;
    //     float: none;
    // }
    // .trunk-nav li a {
    //     padding: 22px 25px;
    //     letter-spacing: 3px;
    //     font-size: 14px;
    // }
    // .trunk-nav li a.logo {
    //     display: none;
    // }
    // .trunk-nav li a.active {
    //     color: #fff;
    //     background-color: $color-dark;
    // }
    // .trunk-nav li a:hover {
    //     color: #fff;
    //     background-color: $color-dark;
    // }
    // .trunk-nav li:first-child a.active,
    // .trunk-nav li:first-child a:hover
    // {border-radius: 10px 0 0 0;}


    /* NAVIGATION ANNIMATION */
    .trunk-nav,
    .trunk-filter,.trunk-category {
        width: $trunk-menu-size+20px;
        padding-left: 20px;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        margin: 0;
        // background-color: #1d2d35;
        background-color: $color-ad-1;
        border-radius: 0px;
        padding-bottom: 4em;

        z-index: -1;

        overflow-x: scroll;
        &::-webkit-scrollbar{
            width: 0;
        }
        -ms-overflow-style: none;
        
        /* starting point */
        opacity: 0;
        transform: translate3d(-5%,0,0)scale(.97);
        transition: none;
    }

    /*Nav Expanding Open Effect*/
    .trunk-nav.open,
    .trunk-category.open,
    .trunk-filter.open {
        opacity: 1;
        
        transform: translate3d(0,0,0)scale(1);
        animation: slideIn .35s ease-in-out;
        transition: none;

        z-index: auto;
    }

    @keyframes slideIn {
        0%      {opacity: 0;
                    transform: translate3d(-5%,0,0)scale(.97);}
        100%  {opacity: 1;
                    transform: translate3d(0,0,0)scale(1);}
    }

    /*Nav Shrinking Closed Effect*/
    .trunk-nav.close,
    .trunk-category.close,
    .trunk-filter.close {
        opacity: 0;

        transform: translate3d(-5%,0,0)scale(.97);
        animation: slideOut .3s ease-in-out;
        transition: none;

        z-index: -1;
    }
    @keyframes slideOut {
        0%      {opacity: 1;
                    transform: translate3d(0,0,0)scale(1);}
        100%  {opacity: 0;
                    transform: translate3d(-5%,0,0)scale(.97);}
    }



    /* CONTENT ANNIMATION */
    .trunk-content {
        /* starting point */
        -webkit-transform: translate3d(0,0,0);
        -moz-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
        transition: none;
        // z-index: 1;
    }

    /*Content Sliding Open Effect*/
    .trunk-content.open{   
        transform: translate3d(-$trunk-menu-size,0,0);
        animation: open .5s ease-in-out;
        transition: none;
    }
    @keyframes open {
        0%      {transform: translate3d(0,0,0);}
        70%    {transform: translate3d(-20px-$trunk-menu-size,0,0);}
        100%  {transform: translate3d(-$trunk-menu-size,0,0);}
    }

    /*Content Sliding Closed Effect*/
    .trunk-content.close{
        transform: translate3d(0,0,0);
        animation: close .3s ease-in-out;
        transition: none;
    }
    @keyframes close {
        0%       {transform: translate3d(-$trunk-menu-size,0,0);}
        100%  {transform: translate3d(0,0,0);}
    }

}