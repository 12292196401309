.super-hero-spasings{
    padding-top: 6rem;
    padding-bottom: 7rem;
}

.hero-spasings{
    padding-top: 4rem;
    padding-bottom: 5rem;
}

.large-spasings{
    padding-top: 2.5rem;
    padding-bottom: 3rem;
}

.medium-spasings{
    padding-top: 2em;
    padding-bottom: 2rem;
}

.small-spasings{
    padding-top: 1rem;
    padding-bottom: 1rem;
}


.gray-section{
    background-color: $color-bg-2;
    border-top: 1px solid $color-gray-6;
}
.gray-plate {
    @extend .gray-section;
    @extend .large-spasings;
    border-radius: 10px;
}

.bordered-section{
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
}
.bordered-plate{
    background-color: #fff;
    border: 2px solid $color-clouds;
}


.section-decor-line{
    width: 73.5%;
    height: 2px;
    background-color: $color-gray-4;
    
    position: absolute;
    top: 0;
    left: $grid-gutter/2;
}

.landing-separator{
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    background-color: #F9F9F9;
    height: 40px;
}
