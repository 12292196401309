%menu-link-paddings{
    padding-right: 12px;
    padding-left: 12px;
    padding-top: .7rem;
    padding-bottom: .7rem;
    @include media-breakpoint-up(xxl){
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

%menu-link-style{
    font-family: $ff-h-medium;
    color: $color-prime;
    text-decoration: underline;
}
%menu-link-hover-style{
    color: $color-second;
    text-decoration: none;
}

.menu{
    $root: &;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(xxl){
        padding-left: $grid-gutter-xxl/2;
        padding-right: $grid-gutter-xxl/2;
    }
    

    &__link{
        @extend %flex;
        flex-direction: row;
        flex-wrap: nowrap;
        @extend .center;
        @extend .j-center;

        @extend %menu-link-paddings;
        @extend %menu-link-style;

        text-align: center;
        line-height: 1.1rem;

        word-wrap: break-word;
        word-break: keep-all;
        overflow-wrap: break-word;

        .fas{
            margin-bottom: 0.5px;
        }

        &.active {
            color: $color-second;
            text-decoration: none;
            // text-shadow: 0px 0px 4px #00000033;
        }
        
    }
    &__item{
        margin-bottom: 0;
        border-bottom: 6px solid transparent;
        margin-bottom: -6px;
        @include media-breakpoint-up(xl){
            border-bottom: 8px solid transparent;
            margin-bottom: -8px;
        }
        @include media-breakpoint-up(xxl){
            border-bottom: 12px solid transparent;
            margin-bottom: -12px;
        }
        .drop-menu {
            @extend .popup_hide;
        };
        @include hover-focus-active {
            #{$root}__link{
                @extend %menu-link-hover-style;
            }
            border-bottom: 6px solid $color-prime;

            @include media-breakpoint-up(xl){
                border-bottom: 8px solid $color-prime;
            }
            @include media-breakpoint-up(xxl){
                border-bottom: 12px solid $color-prime;
            }
            .drop-menu{
                @extend .popup_show;
            }
        }
        &.active{
            border-bottom: 6px solid $color-prime;

            @include media-breakpoint-up(xl){
                border-bottom: 8px solid $color-prime;
            }
            @include media-breakpoint-up(xxl){
                border-bottom: 12px solid $color-prime;
            }
        }
    }
}

.drop-menu{
    top: 90px;
    width: 230px;
    padding-top: 12px;

    background-color: $color-prime;

    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 30px;
        margin-right: 5px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #fff;
    }
    
    &__link{
        @extend %menu-link-paddings;
        font-family: $ff-h-medium;
        text-decoration: underline;

        background-color: #fff;
        
        color: $color-gray-2;
        // color: #333;

        @include hover-focus-active{
            background-color: $color-gray-6;
            color: $color-gray-2;
            text-decoration: none;
            // background-color: $color-gray-22;
            // color: #333;
        }
        &.active{
            color: $color-second;
        }
    }
}

//catalog-menu
%catalog-menu-decor{
    position: absolute;
    content: '';
    left: 0;
    width: 100px;
    height: 1px;
    background-color: #dbe6e0;
    // background-color: #E0E0E0;
    transition: width 0.3s ease;
}

.catalog__menu {
    // font-size: 16px;

    margin-bottom: 2.5em;

    &__item {
        $root: &;

        @extend %flex;

        @extend %menu-link-style;

        background-color: #fff;

        padding: .75em 1em .75em 0;

        @include hover-focus-active {
            @extend %menu-link-hover-style;
        }
        &.active {
            @extend %menu-link-hover-style;
            margin-top: 0.5rem;
        }

        &__lvl-1{
            padding-left: 1.125rem;
            border-left: 4px solid $color-border-2;
            &.active{
                margin-top: 0;
            }
            &.last{
                margin-bottom: 1.5rem;
            }
        }
    }
}

%m-menu-spacings{
    padding: 1rem 1rem;
}

// Mobile menu
.m-menu{
    $root: &;
    // top: 61px;
    // right: 0;
    // margin-top: 1rem;
    
    &__item{

    }

    color: $color-clouds;
    padding-bottom: 5rem;

    .fas{
        color: $color-second;
    }
    a{
        color: $color-clouds;
        text-decoration: none;
    }
    .m-form_search{
        @extend %m-menu-spacings;
    }
    &__auth{
        @extend %m-menu-spacings;
    }

    &__info {
        @extend %m-menu-spacings;
        margin-top: 0.7rem;
        color: $color-clouds;
        background-color: rgba($color: #000000, $alpha: 0.15);

        box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.15) inset;
        border-bottom: 1px solid rgba(256,256,256, 0.1);

        .header__contacts {
            padding-left: 1.125rem;
        }
    }
    &__phone{
        padding-top: .35em;

        font-family: $ff-h-normal;
        font-size: 1.6rem;
        line-height: 1.2em;
        color: $color-clouds;

        text-decoration: none;
    }
    &__email-link{
        color: $color-clouds;
    }

    &__capture{
        padding-left: 1rem;
        padding-top: 2rem;
        padding-bottom: 2rem;

        font-family: $ff-h-bold;
        font-size: 20px;
        color: $color-clouds;

        .fas{
            padding-right: 0.5rem;
        }
    }

    &__cover{
        position: relative;
        z-index: $menu-z-index;
    }
    &__title{
        font-family: $ff-h-medium;
        @include media-breakpoint-up(lg){
            font-size: 16px;
        }
    }
    &__logo{
        height: 70px;
        img{
            height: 32px;
        }
        padding-left: 1.5em;
    }

    &__draw{
        padding-top: .5em;
        padding-bottom: .75em;
        background-color: #fff;
    }
    &__list{
        border-top: 2px solid $color-gray-2;
    }
    &__item{
        margin-bottom: 0;

        &.active{
            .m-menu__sub-link{
                border-left-color: $color-second;
            }
        }
    }
    &__link{
        // background-color: #fff;
        padding-left: 1.25rem;
        padding-top: .625em;
        padding-bottom: 0.625em;

        font-family: $ff-h-medium;
        font-size: 16px;
        line-height: 1.2rem;
        color: #cad5d8;
        text-align: left;

        text-decoration: none;

        cursor: pointer;

        @extend .center;
        @extend .j-start;

        border-left: 4px solid transparent;

        @include hover-focus-active{
            color: $color-clouds!important;
            text-decoration: none;
            background-color: rgba(255,255,255,0.05);
        }
        &.active{
            color: $color-second;
            border-left: 4px solid $color-second;
            font-size: 1.125rem;
            text-decoration: none;
        }
        &[data-status="opened"]{
            background-color: rgba(255,255,255,0.05);
        }
    }
    &__submenu{
        margin-bottom: 0;
        transition: none!important;
    }
    &__sub-link{
        padding-left: 2rem;
        font-family: $ff-h-normal;
        border-left: 4px solid transparent;

        &.active{
            font-size: 1rem;
            color: $color-second;
            border-left-color: $color-second;
        }
    }
    .last{
        margin-bottom: 0.5rem;
    }
    &__social-icon-wrapper{
        padding-left: 0.3rem;
        padding-right: 0.3rem;
    }
    &__social-icon{
        padding: .3em .4em;
        font-size: 1.4em;
        color: $color-clouds;
        border-radius: 5px;
        @include hover-focus-active{
            background-color: $color-gray-2;
        }
        @include media-breakpoint-up (lg){
            font-size: 1.6em;
        }
    }
}

.simple-catalog-menu{
    &__item{
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        font-family: $ff-h-medium;
        line-height: 1.3em;
        text-decoration: underline;
        @include hover-focus-active{
            text-decoration: none;
        }
        span{
            line-height: inherit;
        }
    }
}
