.catalog-header-btn{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 4px;
    z-index: 1050;
}

.catalog__main{
    @include media-breakpoint-down(md){
        padding-left: 0;
        padding-right: 0;
    }
}

// Разделы каталога

.aside{
    font-size: 16px;
    &__header{
        font-family: $h-ff;
        font-size: 1.25em;
        line-height: 1.4em;

        padding-bottom: 0.625em;
        border-bottom: 2px solid $color-second;
        margin-bottom: 0.75em;
    }
}

.cat-option{
    padding-bottom: 1rem;
    border-bottom: 2px solid #E0E0E0;
    &__view{
        &__control{
            &.catalog_tile{
                margin-right: 1rem;
            }
        }
    }
    &__sorting{
        margin-right: 2rem;
        &__title{
            font-family: $RobotoBold;
            font-size: 14px;
            color: $color-gray-3;
            margin-right: .5rem;
        }
        &__control.down{
            margin-right: 1rem;
        }
    }
}

.interface{
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.7rem 0.5rem;
    // border: 2px dotted #E5E5E5;

    background-color: #fff;
    background-image: url("data:image/svg+xml;utf8,
        <svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
            <rect width='100%' height='100%' style='fill: none; stroke: %23E5E5E5; stroke-width: 4; stroke-dasharray: 10 5'/>
    </svg>");

    &__icon{
        height: 28px;
        width: 28px;
        min-width: 28px;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        // padding-left: 8px;
        // padding-right: 8px;

        font-family: $ff-h-medium;
        color: $color-prime-3;
        text-align: center;
        line-height: 28px;

        border: 1px solid $color-border-4;
        border-radius: 2px;
        background-color: $color-bg;
    }
}