@mixin teaser{
    &__about{
        padding-left: 2rem;
        border-left: 4px solid $color-second;
        &__company{
            font-family: $ff-h-bold;
            font-size: 2rem;
            margin-bottom: 1rem;
        }
    }
    &__form-title{
        padding-left: 4rem;
        margin-top: 2rem;
        @include media-breakpoint-up(md){
            margin-top: 0;
        }
        &:before{
            position: absolute;
            top: 17px;
            left: 0rem;
        
            font-family: $ff-fa;
            content: "\f658";
            line-height: 3rem;
            font-weight: 900;
            font-size: 3rem;
            text-align: center;
            color: $color-second;
        
        
            transition: all .3;
        }
    }
    &__text{
        padding-left: 1.5em;
        padding-right: 1.5em;
        font-family: $OpenSans;
        line-height: 1.4em;
    }
    &__title{
        @extend .h2;
        margin-bottom: .2em;
    }
    &__desc{
        font-family: $Roboto;
        line-height: 1.4em;
    }
    &__phone{
        font-size: 1.7rem;
        line-height: 1.6em;
        font-family: $ff-h-bold;
    }
    &__phone-box, &__btn{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    &__phone-box, &__btn{
        @include media-breakpoint-up(xs){
            justify-content: left;
        }
        @include media-breakpoint-up(sm){
            justify-content: center;
        }
    }
    &__consult-icon{
        font-size: 65px;
        color: $color-second;
        padding-right: 1rem;
    }
}


.teaser{
    @include teaser;
}
.l-teaser{
    @include teaser;

    background-color: transparent;

    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem 0.5rem 1rem;
    @include media-breakpoint-up(sm){
        padding: 1.2rem 2rem 1.2rem;
    }

    // background-color: #fff;
    // border: 1px solid #EEEEEE;
    &__title{
        text-transform: unset;
    }
    &__text{
        font-size: 1rem!important;
        // line-height: 1.6em;
        padding-left: $grid-gutter/2;
        padding-right: $grid-gutter/2;
        padding-bottom: 1rem;
        font-size: 1.2rem;
    }
    &__contacts{
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        padding-right: 1rem;
        @include media-breakpoint-up(md){
            padding-bottom: 0.5rem;
        }
    }
    &__email{
        // font-size: 1.125rem;
    }
    &__phone{
        @include media-breakpoint-only(sm){
            font-size: 1.5rem;
        }
        // font-size: 2rem;
    }
    &__btn{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    &__info{
        @include media-breakpoint-up(lg){
            flex-wrap: nowrap;
        }
    }

    // &_gray{
    //     background-color: #F9F9F9;
    //     border-color: transparent;
    // }
}

.l-teaser_consult{
    .l-teaser__contacts{
        // align-self: flex-end;
        @include media-breakpoint-up(md){
            padding-left: 2rem;
        }
        @include media-breakpoint-up(lg){
            padding-right: 2rem;
        }
        @include media-breakpoint-up(xl){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            // align-self: center;
        }
    }
}
