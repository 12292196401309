%hover-effect-shadow {
    box-shadow: 0 0 20px rgba($color: #3f3f3f, $alpha: 0.2);
}
%hover-effect-shadow2 {
    box-shadow: 0 0 10px rgba($color: #000, $alpha: 0.25);
}

%hover-effect-img-transform {
    // transform: scale(1.1);
    transform: none;
}

.cards {
    padding-top: 1rem;
}

@mixin card {
    $root: &;

    border-radius: 0;
    // border-bottom: 4px solid $color-gray-2;

    text-decoration: none;

    &__wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    &__artikul{
        padding-top: 10px;
        padding-bottom: 2px;
        font-family: $ff-h-bold-i;
        color: $color-second;
        font-size: 1rem;
        
    }
    &__name {
        padding-top: 2px;
        padding-bottom: 1rem;
        
        font-family: $ff-h-medium;
        font-size: 1rem;
        line-height: 1.4em;
        color: $h-color;
        text-decoration: underline;
        transition: 0.05s ease;
    }

    &__anons {
      
        line-height: 1.6em;
        border: 1px none #333;
        font-family: $ff-normal;

        ul {
            padding-left: 20px;
            margin-top: 0;
            margin-bottom: 1.1em;

            li {
                margin-bottom: 3px;
            }
        }
    }

    @include hover-focus-active {
        // border-bottom: 2px solid $color-gray-4;
        cursor: pointer;
        @extend %hover-effect-shadow;

        #{$root}__img {
            img {
                @extend %hover-effect-img-transform;
            }
        }

        #{$root}__name,
        #{$root}__more {
            text-decoration: none;
        }
    }
}
