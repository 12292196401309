$btn-font-family:                   $h-ff;
$btn-font-size:                     1em;
$btn-height:                        auto;
$btn-padding:                       0.5625em 1.875em;
$btn-padding-small:                 0.5em 1em;
$btn-b-radius:                      5px;
$btn-border:                        none;

.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
    font-family: $btn-font-family;
    font-size: $btn-font-size;
    line-height: 1em;
    text-decoration: none;
    text-align: center;
    height: $btn-height;
    padding: $btn-padding;
    border-radius: $btn-b-radius;
    border: $btn-border;
    &.btn_lg {
        padding: 0.75em 1.875em;

        font-family: $btn-font-family;
        font-size: 1rem;

    }
    &.btn_wide{
        padding: 0.75rem 2rem;
    }
    &.btn_small {
        padding: $btn-padding-small;
    }
    &.btn_fix{
        width: 132px;
    }
    &.btn_icon{
        padding-left: 0.5625em !important;
        padding-right: 0.5625em !important;
        .fas, .fa{
            padding-right: 0;
        }
    }
    .fas, .fa {
        font-size: 1em;
        padding-right: 0.5rem;
    }
    &.left-icon{
        .fas, .fa{
            padding-right: 0;
            padding-left: 0.5rem;
        }
    }

    @extend .flex_ccc;

    @include hover-focus-active{
        cursor: pointer;
    }
    &:focus{
        outline: none;
    }
}

@mixin btn_colors(
    $textColor: $white,
    $faColor: $white,
    $bgColor: $color-prime,
    $borderColor: transparent,
    $borderSize: 2px,
    $borderType: solid,

    $textColorHover: lighten($textColor, 20%),
    $faColorHover: $textColorHover,
    $bgColorHover: lighten($bgColor, 10%),
    $borderColorHover: transparent,

    $textColorActive: $textColor,
    $bgColorActive: $bgColor,
    $borderColorActive: $bgColor,
    $faColorActive: $faColorHover,
) {
    color: $textColor;
    background-color: $bgColor;
    border: $borderSize $borderType $borderColor;
    i.fas, svg {
        color: $faColor;
        // transition-duration: 0.1s
        path{
            fill: $textColor;
        }
    }
    &:link,
    &:visited {
        // color: $white;
    }
    &:hover,
    &:focus, &.active {
        color: $textColorHover;
        background-color: $bgColorHover;
        border: $borderSize $borderType $borderColorHover;
        svg path{ fill: $textColorHover; }
        i.fas{
            color: $faColorHover;
        }
    }
    &:active {
        color: $textColorActive;
        background-color: $bgColorActive;
        border: $borderSize $borderType $borderColorActive;
        svg path{ fill: $textColorActive; }
        i.fas{
            color: $faColorHover;
        }
    }
}

.btn_prime{
	@include btn_colors(
		$textColor:#fff,
		$bgColor:$color-prime,
		$borderColor:$color-prime,
		$faColor:#fff,

		$textColorHover: #fff,
		$bgColorHover: transparentize($color-prime, 0.2),
		$borderColorHover: transparent,

		$textColorActive: #fff,
		$bgColorActive: transparentize($color-prime, 0.2),
		$borderColorActive: transparentize($color-prime, 0.2)
	)
}
.btn_prime_bor{
	@include btn_colors(
		$textColor:             $color-prime,
		$bgColor:               transparent,
		$borderColor:           $color-prime,
		$faColor:               $color-prime,

		$textColorHover:        $color-prime,
		$bgColorHover:          transparent,
		$borderColorHover:      transparentize($color-prime, 0.5),

		$textColorActive:       $color-prime,
		$bgColorActive:         transparent,
		$borderColorActive:     transparentize($color-prime, 0.5)
	)
}

.btn_prime_alpha{
	@include btn_colors(
		$textColor:             $color-prime,
		$bgColor:               transparent,
		$borderColor:           $color-border-3,
		$faColor:               $color-second,

		$textColorHover:        $color-gray-3,
		$bgColorHover:          transparent,
        $borderColorHover:      $color-border-4,
        $faColorHover:          $color-second,

		$textColorActive:       $color-second,
		$bgColorActive:         transparent,
        $borderColorActive:     transparent,
        $faColorActive:         $color-second,
	)
}

.btn_second{
	@include btn_colors(
		$textColor:#fff,
		$bgColor:$color-second,
		$borderColor:$color-second,
		$faColor:#fff,

		$textColorHover: #fff,
		$bgColorHover: transparentize($color-second, 0.2),
		$borderColorHover: transparent,

		$textColorActive: #fff,
		$bgColorActive: transparentize($color-second, 0.2),
		$borderColorActive: transparentize($color-second, 0.2)
	)
}

.btn_second_bor{
	@include btn_colors(
		$textColor:             $color-prime,
		$bgColor:               transparent,
		$borderColor:           $color-second,
		$faColor:               $color-second,

		$textColorHover:        $color-second,
		$bgColorHover:          transparent,
		$borderColorHover:      transparentize($color-second, 0.5),

		$textColorActive:       $color-second,
		$bgColorActive:         transparent,
		$borderColorActive:     transparentize($color-second, 0.5)
	)
}

.btn_gray{
	@include btn_colors(
		$textColor: $color-gray-2,
		$bgColor: $color-gray-1,
		$borderColor: transparent,
		$faColor: $color-gray-2,

		$textColorHover: $color-gray-2,
		$bgColorHover: transparentize($color-gray-1, 0.5),
		$borderColorHover: transparent,

		$textColorActive: $color-gray-2,
		$bgColorActive: transparentize($color-gray-1, 0.3),
		$borderColorActive: transparent
	)
}

.btn_gray_alpha{
	@include btn_colors(
		$textColor:             $color-gray-2,
		$bgColor:               transparent,
		$borderColor:           transparent,
		$faColor:               $color-gray-2,

		$textColorHover:        $color-gray-2,
		$bgColorHover:          transparentize($color-gray-5, 0.5),
		$borderColorHover:      transparent,

		$textColorActive:       $color-gray-2,
		$bgColorActive:         transparentize($color-gray-5, 0.5),
		$borderColorActive:     transparent
	)
}

.btn_gray_bor{
	@include btn_colors(
		$textColor: #636363,
		$bgColor: transparent,
		$borderColor: #C4C4C4,
		$faColor: $color-second,

        $textColorHover: $color-second,
        // $faColorHover: $color-gray-4,
		$bgColorHover: transparentize($color-gray-1, 0.5),
		$borderColorHover: #C4C4C4,

        $textColorActive: $color-gray-4,
        $faColorActive: $color-gray-4,
		$bgColorActive: transparentize($color-gray-1, 0.3),
		$borderColorActive: #C4C4C4
	)
}

.btn_dark{
	@include btn_colors(
		$textColor: #fff,
		$bgColor: $color-gray-2,
		$borderColor: transparent,
		$faColor: #fff,

		$textColorHover: #fff,
		$bgColorHover: transparentize($color-gray-2, 0.5),
		$borderColorHover: transparent,

		$textColorActive: #fff,
		$bgColorActive: transparentize($color-gray-2, 0.3),
		$borderColorActive: transparent
	)
}

.btn_option{
	@include btn_colors(
		$textColor: $color-gray-4,
		$bgColor: transparent,
		$borderColor: $color-gray-2,
		$faColor: $color-gray-4,

		$textColorHover: $color-gray-2,
		$bgColorHover: $color-gray-2,
		$borderColorHover: $color-gray-2,

		$textColorActive: $color-gray-2,
		$bgColorActive: $color-gray-1,
		$borderColorActive: $color-gray-2
    );
    border-width: 1px;
    @include hover-focus-active{
        border-width: 1px;
    }
}

.btn_search{
    padding: 11px 12px;
    // padding-top: 13px;
    // padding-bottom: 13px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    .fas{
        padding-right: 0;
    }
}

.numpages{
    margin-bottom: 2rem;
}

%btn_numpage{
    font-family: $ff-h-medium;
    color: $color-prime;

    border: 2px solid $color-border-5;
    border-radius: 5px;
    background-color: $color-bg;

    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.btn_numpage{
    @extend %btn_numpage;
    @include hover-focus-active{
        background-color: #fff;
    }

    &_active{
        @extend %btn_numpage;

        color: $color-second;

        border: 2px solid transparent;

        background-color: transparent;

    }
}
