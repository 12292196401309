.eggs{
	@extend .large-spasings;
	&__item{
		&__head{
			padding-top: 1rem;
			padding-bottom: 0.5rem;
		}
		&__sign{
			font-family: $ff-h-bold;
			font-size: 4rem;
			line-height: 1em;
			color: $color-second;
		}
		&__sign-descr{
			font-family: $ff-h-bold;
			font-size: 1.125rem;
			color: #777777;
		}
		&__title{
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			font-family: $ff-h-bold;
			font-size: 1.125rem;
			line-height: 1.3em;
			color: $color-prime;
		}
		&__descr{
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			line-height: 1.6em;
		}
	}

}