.object{
    // padding-top: 1rem;
    // padding-bottom: 2rem;
    padding-bottom: 2rem;
    &__photos{
        margin-bottom: 1.2rem;
        // order: 2;
        @include media-breakpoint-up(lg){
            // order: 1;
        }
        @include media-breakpoint-up(lg){
            margin-bottom: 0;
        }
    }
    &__main{
        // order: 1;
        padding-bottom: 1rem;
        @include media-breakpoint-up(lg){
            // order: 2;
        }
    }
    &__artikul{
        padding-bottom: 4px;
        font-family: $ff-h-bold;
        color: $color-second;
        font-size: 1.75rem;
    }
    &__name{
        margin-top: 0.3em;
        // margin-bottom: 0.88888rem;
    }
    &__descr{
        padding-bottom: 1rem;
    }
    &__order{
        padding-top: 1.0rem;
        padding-bottom: 1.3rem;
        .field{
            padding-bottom: 0;
            &__item{
                margin-bottom: 0;
            }
        }
        &__price, &__counter, &__action{
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
    &__props{
        // padding-bottom: 0.75rem;
        &__label{
            padding-top: 0.5rem;
            padding-bottom: 0.75rem;
            font-family: $ff-h-bold;
        }
        &__options{
            padding-bottom: 1rem;
        }
        &__option{
            border: 1px solid transparent;
            border-radius: 3px;
            @include hover-focus-active{
                background-color: #F8F8F8;
                border-color: #EFEFEF;
            }
        }
        &__name{
            &__size{
                font-family: $ff-bold;
            }
            &__dash{
                color: #CBCBCB;
            }
        }
    }
    &__order__sum{
        // padding-top: 0.75rem;
        padding-bottom: 1.5rem;
    }
    &__order__make{
        margin-top: 0.75rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-top: 2px solid $color-border-3;
        border-bottom: 2px solid $color-border-3;
    }
    &__price {
        // padding-top: 0.4em;
        // padding-bottom: 1.3em;
        font-size: 2em;
        line-height: 1em;
        font-family: $RobotoBold;
        color: $color-gray-1;
        .fas {
            font-size: 0.6em;
            color: $color-gray-3
        }
    }
    &__price-note{
        font-family: $ff-italic;
        color: $color-gray-3;

        // margin-bottom: 2em;
    }
    &__btn{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    &__action{
        padding-top: .4rem;
        padding-bottom: .6rem;
        border-left: 4px solid $color-second;

        &__title{
            // font-family: $FiraSans;
        }

        &__phone{
            font-size: 1.4rem;
            line-height: 1.8em;
            margin-right: 1rem;
        }
    }
    &__table{
        overflow: scroll;
    }
}

.product{
    &__charact{
        &__group{
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
    &__variations{
        &__item{
            padding-top: 1rem;
            padding-bottom: 2rem;

            &__name{
                @extend .items-name;
                padding-bottom: 1rem;
                @include media-breakpoint-up(sm){
                    padding-bottom: 0rem;
                }
            }
            &__price{
                .price{
                    font-family: $ff-h-medium;
                    font-size: 1.77777rem;

                    .fas{
                        font-size: 0.7em;
                        color: $color-gray-3;
                    }
                }
                .descr{
                    padding-top: 0.2rem;
                    font-family: $ff-italic;
                }
                padding-top: 0.5rem;
                padding-bottom: 1.5rem;
                @include media-breakpoint-up(sm){
                    padding-top: 0;
                    padding-bottom: 0rem;
                }
            }
            &__file{
                .fas{
                    color: $color-second;
                    padding-right: 0.5rem;
                }
                @include media-breakpoint-up(xl){
                    justify-content: center;
                }
                padding-bottom: 0.5rem;
                @include media-breakpoint-up(sm){
                    padding-bottom: 0rem;
                }

            }
            &__btn{
                padding-top: 1rem;
                @include media-breakpoint-up(xl){
                    padding-top: 0rem;
                }
            }
        }
    }
    &__conditions{
        &__item{
            padding-top: 1rem;
            padding-bottom: 1rem;

            &__name{
                @extend .items-name;
                .fas{
                    padding-right: 1rem;
                    font-size: 2rem;
                    color: $color-second;
                }
                padding-bottom: 1.5rem;
            }
        }
    }
}