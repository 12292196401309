.content-items{
    padding-top: 1rem;
}

.content-item{

    border-bottom: 2px solid $color-gray-2;
    padding-bottom: 0.5rem;

    @include hover-focus-active{
        border-bottom: 2px solid $color-gray-4;
        cursor: pointer;

        @extend %hover-effect-shadow;

        .content-item__img {
            img{
                @extend %hover-effect-img-transform;
            }
        }
        .content-item__name a {
            color: $color-gray-3;
            text-decoration: none;
        }
    }

    &__wrapper{
        margin-bottom: $grid-gutter;
    }
    &__img{
        overflow: hidden;
    }
    &__name{
        margin-top: 0.2em;
        height: 3em;
        a{
            padding-top: 2px;

            font-family: $RobotoMedium;
            font-size: 1.1em;
            color: $font-color-base;
            text-decoration: none;

            transition: 0.05s ease;
        }
    }
    // &__desc{

    // }
}