// .fancybox-content{
//     background-color: #fff!important;
// }
// @include media-breakpoint-only(xs){
//     .fancybox-content{
//         padding: 1rem!important;
//     }
// }
.modal-form-size{
    max-width: 100%;
    @include media-breakpoint-up(sm){ max-width: 83.33333%!important;}
    @include media-breakpoint-up(md){ max-width: 66.66667%!important;}
    @include media-breakpoint-up(lg){ max-width: 50%!important;}
    @include media-breakpoint-up(xl){ max-width: 41.66667%!important;}
}

.about__video{
    iframe{
        width: 100%;
        height: auto;
        @include media-breakpoint-up(sm){
            height: 240px;
        }
        @include media-breakpoint-up(md){
            height: 320px;
        }
    }    
}

.history{
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    &__item{
        border-left: 2px solid $color-gray-2;
        margin-bottom: $grid-gutter;
    }
    &__name{
        padding-left: 0;
    }
}


.partners{
    &__img{
        position: static;
        img{
            max-width: 100%;
            height: auto;
        }
    }
}

.breadcrumb{
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 1.5em;
    z-index: 100;
    position: relative;
    // padding-top: .5rem;
    padding-bottom: 1rem;
    list-style-type: none;

    &>div{
        margin-left: 15px;
    }
    &>div:first-child {
        margin-left: 0;
    }
}
.breadcrumb-wrap .breadcrumb {
    // margin-top: 20;
    // margin-bottom: 20px;
}

.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
}

.fullscreen-bg__video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.sales-section{
    // padding-top: $grid-gutter;
    padding-bottom: 1rem;
    .cards{
        padding-top: 0;
    }
}

.search-result{
    @extend .content_text;
    position: relative;

    dt, dd{
        margin-left: 2rem;
    }
    dt a{
        font-family: $RobotoMedium;
        color: $font-color-base;
    }
    &__num{
        position: absolute;
        left: 0;
    }
}

.main-menu{
    height: 350px;
}

.navigate-icon{
    color: $color-gray-4;
}
.slider-icons{
    padding-top: 2rem;
    padding-bottom: 0rem;
}