@mixin b-info {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 200px center;
    background-color: $color-bg-4;
    &__content{
        padding-left: 2em;
        padding-right: 2rem;
        background-color: $color-bg-4;
    }
}
.b-info_1{
    @include b-info;
    background-position: 200px center;
}

.b-info_3{
    @include b-info;
    background-position: -550px center;
}

.main-page{
    h2.h2{
        font-size: $h1-font-size;
    }
    .content_text{
        p{
            font-size: 1.125rem;
        }
    }
}