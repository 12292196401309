.product-card {
    @include card;
    padding:0.3rem;
    &__img{
        height: 100px;
    }
    &__interface{
        margin-top: 0.3rem;
    }
}
